import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { t } from 'i18next';
import { debounce, isArray } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, ButtonRow, CustomAlert, SearchInputWithDropdown } from 'ui-components-web';
import ContentHeader from 'Components/CustomerDetailsHeader';
import CompanyInfoStep from 'Forms/CompanyForm';
import useTimeOnPage from 'Global/hooks/useTimeOnPage';
import theme from 'Global/theme';
import { CustomEvents } from 'Global/types';
import { formatSearchList, isProduction, renderPhoneNumber, trackEvent } from 'Global/utils';
import { getUseCases, inviteUser } from 'Service/api/finInsights';
import { searchCompanyData } from 'Service/api/metadata';
import { AlertContainer, FormContainer, InvitePortalContainer } from './style';
const InvitePortal = () => {
    const { control, formState: { errors }, handleSubmit, watch, reset, getValues, } = useForm();
    const inputRefs = useRef([]);
    const [options, setOptions] = useState();
    useTimeOnPage('InvitePortal');
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [isAddCompany, setIsAddCompany] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [companyData, setCompanyData] = useState(null);
    const companyField = watch('search_input_with_select');
    const companyFieldLength = companyField?.split(':')[0].length || 0;
    const [gender, setGender] = useState('');
    const [showGenderError, setShowGenderError] = useState(false);
    const [customerCluster, setCustomerCluster] = useState(null);
    const [showClusterError, setshowClusterError] = useState(false);
    const [useCases, setUseCases] = useState([]);
    const resetState = () => {
        setIsSuccess(false);
    };
    const fetchUseCases = async () => {
        try {
            const response = await getUseCases();
            setUseCases(transformList(response.data));
        }
        catch (error) {
            console.log({ error });
        }
    };
    const handleFormSubmit = () => {
        setShowGenderError(false);
        if (gender == '') {
            setShowGenderError(!showGenderError);
            return;
        }
        if (customerCluster == null) {
            setshowClusterError(!showClusterError);
            return;
        }
        trackEvent(CustomEvents.SUBMIT_INVITE_PORTAL);
        const userData = getValues();
        const payload = {
            crefo_id: userData.crefo_id,
            company_name: userData.companyName ?? userData.legal_entity_name,
            first_name: userData.firstName,
            last_name: userData.lastName,
            phone_number: renderPhoneNumber(userData.phoneInput),
            email: userData.email,
            status: 'INVITED',
            personal_number: userData.personalNumber,
            iban: userData.iban,
            gender: gender,
            zip_code: userData.zip_code,
            street: userData.address,
            city: userData.city,
            use_case: customerCluster,
        };
        setIsLoading(true);
        inviteUser(payload)
            .then(() => {
            setIsSuccess(true);
        })
            .catch((error) => console.log({ error }))
            .finally(() => setIsLoading(false));
    };
    const getCompanyData = (companyData) => {
        if (options) {
            const result = options.find((element) => element.crefo_id === companyData);
            return result;
        }
    };
    const handleSearch = (newValue) => {
        if (newValue)
            forwardToSearch(newValue);
    };
    // Delay execution function for the company search input
    const debounceSearch = useCallback(debounce((newValue) => handleSearch(newValue), 1000), []);
    function transformList(data) {
        return data.map((item) => {
            const key = Object.keys(item)[0]; // Get the first key of the object
            return { value: key, label: item[key] };
        });
    }
    const handleAddCompany = () => {
        trackEvent(CustomEvents.ADD_NEW_COMPANY_CLICK);
        setIsAddCompany(true);
        setIsFormVisible(true);
        resetState();
    };
    const forwardToSearch = (company_name) => searchCompanyData(company_name)
        .then(async ({ data }) => {
        trackEvent(CustomEvents.SEARCH_COMPANY_INPUT_CHANGED);
        const formattedList = await formatSearchList(data);
        if (isArray(formattedList)) {
            setOptions(formattedList);
        }
    })
        .catch(() => {
        return {
            value: null,
            text: null,
        };
    });
    useEffect(() => {
        fetchUseCases();
    }, []);
    useEffect(() => {
        if (companyFieldLength > 4) {
            const companyData = getCompanyData(companyField.split(':')[1]);
            setCompanyData(companyData);
            if (companyData) {
                trackEvent(CustomEvents.SEARCH_COMPANY_SELECTED_OPTION);
                reset({
                    search_input_with_select: companyData.legal_entity_name,
                    ...companyData,
                });
                setIsFormVisible(true);
                resetState();
            }
        }
    }, [companyFieldLength]);
    return (_jsxs("div", { children: [_jsx(ContentHeader, { title: t('InvitePortalPage.InviteCustomersToFinCheck') }), _jsx(InvitePortalContainer, { children: _jsxs(FormContainer, { children: [_jsx("h3", { children: t('InvitePortalPage.CustomerInformation') }), _jsx("div", { children: _jsx(SearchInputWithDropdown, { iconColor: theme.colors.grayLynch, isReverted: true, buttonColor: theme.colors.primary, isProduction: isProduction, searchLabel: t('InvitePortalPage.SearchLabel'), dropdownRender: (menu) => _jsx(_Fragment, { children: menu }), handleAddCompany: handleAddCompany, control: control, errors: errors, name: 'search_input_with_select', options: options || [], handleSearch: (value) => debounceSearch(value), placeholder: t('InvitePortalPage.SearchPlaceholder') }) }), isFormVisible && (_jsxs(_Fragment, { children: [_jsx(CompanyInfoStep, { control: control, disabled: isSuccess, isAddCompany: isAddCompany, inputRefs: inputRefs, errors: errors, companyData: companyData || {}, setGender: setGender, showGenderError: showGenderError, setCustomerCluster: setCustomerCluster, showClusterError: showClusterError, useCases: useCases }), _jsx(ButtonRow, { align: 'center', children: _jsx(Button, { "data-test-id": 'invite-portal-submit-button', disabled: isLoading || isSuccess, text: t('InvitePortalPage.SendInvitation'), type: 'primary', onClick: handleSubmit(handleFormSubmit), loading: isLoading }) })] })), isSuccess && (_jsx(AlertContainer, { children: _jsx(CustomAlert, { status: t('InvitePortalPage.SuccessMessage'), type: 'success' }) }))] }) })] }));
};
export default InvitePortal;
