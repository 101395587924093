import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChartIndicators, ColoredDotLabel, WhiteBlock } from 'ui-components-web';
import { IndicatorsWrapper } from 'Components/CostsRevenueSection/style';
import LiquidityChart from 'Components/LiquidityChart';
import theme from 'Global/theme';
import { formatCurrency, formatDate } from 'Global/utils';
import { DotsContainer } from './style';
function LiquiditySection({ liquidity, status }) {
    const { t } = useTranslation();
    const formmtGraphMonth = (time) => {
        const date = new Date(time);
        const year = date.getFullYear().toString().substring(2);
        const month = t(`Months.${date.getMonth()}`);
        return `${month} ${year}`;
    };
    const percentageChange = useMemo(() => {
        const data = liquidity?.monthly_balances ?? [];
        const d = new Date();
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const year = d.getFullYear();
        const currentDate = `${year}-${month}-15`;
        const result = data ? data.find((item) => item.month === currentDate) : [];
        return result ? result.percentage_change?.toString() || 'No data' : 'No data';
    }, [liquidity]);
    return (_jsxs(WhiteBlock, { children: [liquidity && (_jsx(IndicatorsWrapper, { children: _jsx(ChartIndicators, { title: t('CustomerDetails.Liquidity'), amount: formatCurrency(liquidity.current_balance), lastUpdate: `${t('CustomerDetails.LastUpdate')} ${formatDate(liquidity.current_date)}`, status: liquidity.liquidity_status?.status, percentage: percentageChange, showtrend: false, text: percentageChange === '0'
                        ? t('CustomerDetails.NoChange')
                        : `${percentageChange}% ${t('CustomerDetails.CurrentMonth')}` }) })), liquidity?.daily_data && (_jsxs(_Fragment, { children: [_jsx(LiquidityChart, { data: liquidity.monthly_balances, dailyData: liquidity.daily_data, formatXItem: formmtGraphMonth, formatYItem: (t) => formatCurrency(t, true), graphColor: theme.colors.primary }), _jsxs(DotsContainer, { style: { paddingLeft: 70 }, children: [_jsx(ColoredDotLabel, { fontSize: '17', text: t('CustomerDetails.Liquidity'), color: theme.colors.primary }), _jsx(ColoredDotLabel, { fontSize: '17', text: t('CustomerDetails.Prognose'), color: `${theme.colors.primary}99` })] })] }))] }));
}
export default LiquiditySection;
